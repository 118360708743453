<template>
     <div>
           <!-- 面包屑区域 -->
          <Breadcrumb :breadcrumbData="breadcrumbData"></Breadcrumb>

          <!-- 卡片视图区域 -->
          <el-card shadow="hover">
                 <div class="first_floor">
                         <Title :title="title[0]"></Title>
                         <div class="tips_box">
                                <img src="@/assets/images/tip.png" class="icons">
                                <div class="tips">
                                     <p class="tips_item">温馨提示：若无法选择请按Ctrl+F5键强制刷新页面，或者选用Chrome浏览器重试。</p>
                                     <p class="tips_item">1. 买手一部手机只能注册一个账户，一个账户只能绑定一个淘宝账号。</p>
                                     <p class="tips_item">2. 近1个月内被删除评价的账号则无法接单，近3个月内被删除评价的账号无法接优质任务单。</p>
                                     <p class="tips_item">3. 在放任务期间，请检查下是否有开启淘客，如有开启建议关闭淘客，以免用户误通过淘客下单造成损失。</p>
                                </div>
                         </div>
                         <div class="btns">
                                <el-button :type="Checked==index ? 'primary' : 'info'" v-for="(item,index) in publishTaskTypeInfo.data1" :key="item.id" :disabled="!item.is_open" @click="changeTaskType(index,item.id)">{{item.name}}</el-button>
                         </div>
                         <div class="radio_group">
                                  <el-radio v-for="item in taskClassification" :key="item.id" v-model="taskClassRadio" :label="item.label" class="task_radio" :disabled="!item.is_open" @change="changeTaskList(item.id)">
                                         <div class="task_menu">
                                              <img :src="item.icon" class="item_img"/>
                                              <span class="item_text">{{item.name}}</span>
                                         </div>
                                  </el-radio>
                         </div>
                 </div>

                 <div class="second_floor">
                        <div class="s_top">
                               <div class="tit">
                                    <Title :title="title[1]"></Title>
                               </div>
                               <router-link to="/pricedetails" class="price_details">价格明细</router-link>
                        </div>

                        <div class="bindstore_box" v-if="taskStoreList.length==0">
                             <el-button type="primary" class="bindstore" @click="goBingStore">绑定店铺</el-button>
                        </div>

                        <div class="task_list" v-else>
                              <el-radio-group v-model="taskStoreRadio">
                                   <el-radio v-for="item in taskStoreList" :key="item.id" v-model="taskListRadio" :label="item.label" class="task_list_radio" @change="changeTaskStore(item.id)">
                                         <div class="task_list_menu">
                                              <img :src="item.icon" class="item_list_img"/>
                                              <span class="item_list_text">{{item.shopsname}}</span>
                                         </div>
                                   </el-radio>
                              </el-radio-group>

                              <div class="next_step">
                                   <el-button type="primary" class="next_step_btn" @click="next_step" :disabled="taskStoreID==-1">下一步</el-button>
                              </div>

                        </div>
                 </div>
          </el-card>

     </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
     data(){
          return{
                  title: ["选择任务类型", "选择店铺"],
                  //面包屑数据
                  breadcrumbData:[ 
                        {
                              id:1,
                              title:'好物电商',
                              path:'',
                              isClick:false,
                        },
                        {
                              id:2,
                              title:'任务管理',
                              path:'',
                              isClick:false,
                        }, 
                        {
                              id:3,
                              title:'发布任务',
                              path:'publish',
                              isClick:true,
                        }
                  ],
                  taskClassRadio: 1,
                  taskList:[
                           {
                                id:1,
                                imagesrc:require('@/assets/images/pinduoduo.png'),
                                text:'好抽的茶',
                                label:"1",
                           },
                           {
                                id:2,
                                imagesrc:require('@/assets/images/pinduoduo.png'),
                                text:'优品茶烟小卖铺',
                                label:"2",
                           },
                           {
                                id:3,
                                imagesrc:require('@/assets/images/pinduoduo.png'),
                                text:'大拇指茶烟',
                                label:"3",
                           },
                           {
                                id:4,
                                imagesrc:require('@/assets/images/pinduoduo.png'),
                                text:'林家的烟',
                                label:"4",
                           },
                           {
                                id:5,
                                imagesrc:require('@/assets/images/pinduoduo.png'),
                                text:'好烟优选',
                                label:"5",
                           },
                           {
                                id:6,
                                imagesrc:require('@/assets/images/pinduoduo.png'),
                                text:'优品电子茶烟',
                                label:"6",
                          },
                          {
                                id:7,
                                imagesrc:require('@/assets/images/pinduoduo.png'),
                                text:'茶烟小商店',
                                label:"7",
                          },
                  ],
                  taskListRadio: 1,
                  //店铺列表选项默认值
                  taskStoreRadio:-1,
                  //选中店铺列表ID
                  taskStoreID:-1,
                  //选中的按钮
                  Checked:0,
                  //任务分类ID
                  tasktypeid:-1,

          }
     },
     computed:{
          ...mapState({
               publishTaskTypeInfo:state => state.publish.publishTaskTypeInfo,
               taskClassification:state => state.publish.taskClassification,
               taskStoreList:state => state.publish.taskStoreList
          }),
     },
     methods:{
          //获取任务类型
          getPublishTaskType(){
               this.$store.dispatch('getPublishTaskType')
          },

          //切换任务类型筛选
          changeTaskType(index,id){
                this.Checked = index
                this.taskClassRadio = 1
                this.$store.dispatch('changeTaskType',id)
          },

          //获取所属任务下的店铺列表
          getTaskStoreList(){
                this.$store.dispatch('getTaskStoreList',this.tasktypeid)
          },

          //切换任务分类列表
          changeTaskList(id){
               this.tasktypeid = id
               this.taskListRadio = 1
               this.$store.dispatch('getTaskStoreList',{tasktypeid:id})
          },

          //跳转到绑定店铺
          goBingStore(){
               this.$router.push('/bindstore')
          },

          //切换店铺列表
          changeTaskStore(id){
                 this.taskStoreID = id
          },

          //下一步
          next_step(){
               this.$router.push({name:'fillincommodityinfo',params:{tasktypeid:this.tasktypeid,taskStoreID:this.taskStoreID}})
               let taskInfo = {tasktypeid:this.tasktypeid,taskStoreID:this.taskStoreID}
               localStorage.setItem('taskInfo',JSON.stringify(taskInfo))
          }

     },
     created(){
              this.getPublishTaskType()
     },
     watch:{
          taskStoreList(newval,oldval){
               if(newval.length==0){
                      this.$message.warning('该平台下你还没有店铺，或者店铺没有通过审核')
               }
          },
          //根据任务分类获取初始化默认的任务分类ID
          taskClassification(newval,oldval){
               if(newval.length>0){
                    this.taskClassification.forEach((item,index) => {
                          if(index==0){
                                this.tasktypeid = item.id
                          }
                    });
               }
          }
     }
}
</script>

<style lang="less" scoped>
// @assets:"~@/assets";
// @import "@{assets}/css/theme.less";
.first_floor{
     width:100%;
     height:auto;
     .tips_box{
         width:100%;
         height:auto;
         display: flex;
         justify-content:flex-start;
         margin-top:20px;
         .icons{
                width:30px;
                height:15px;
          }
     }
     .btns{
          margin-top:20px;
     }
     .radio_group{
            margin-top:25px;
            padding-left:20px;
            display:flex;
            justify-content:flex-start;
            align-items: center;
            flex-wrap: wrap;
            .task_radio{
                display: flex;
                justify-content: center;
                align-items: center;
                .task_menu{
                     display: flex;
                     justify-content: center;
                     align-items: center;
                    .item_img{
                        width:20px;
                        height:20px;
                        margin-right:5px;
                    }
                   .item_text{
                       font-size: 16px;
                       font-weight:600;
                    }
                }
            }
     }
}

.second_floor{
     width:100%;
     height:auto;
     margin-top:50px;
     .s_top{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .tit{
               width: 100px;
          }
          .price_details{
               margin-left:10px;
               cursor: pointer;
               // color: #4165f2;
               color: rgba(@themeColor,1);
          }
     }
     .bindstore_box{
          margin-top: 100px;
          margin-bottom: 50px;
          display: flex;
          justify-content: center;
          .bindstore{
                 width:350px;     
                 height:40px;
          }
     }
     .task_list{
          width: 100%;
          height:auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top:20px;
          .task_list_radio{
                display: flex;
                justify-content: flex-start;;
                align-items: center;
                margin-left:20px;
                .task_list_menu{
                     display: flex;
                     justify-content: flex-start;
                     align-items: center;
                     margin:10px 0 10px 5px;
                    .item_list_img{
                        width:25px;
                        height:25px;
                        margin-right:10px;
                    }
                   .item_list_text{
                       font-size: 16px;
                       font-weight:600;
                    }
                }
            }
     }
}

.tips_item {
           margin-left: 10px;
           font-size: 12px;
           font-weight: 400;
           line-height: 20px;
           color: #a2a2a2;
     }
.el-card {
  margin: 20px 20px 50PX 20px;
}
.el-button{
     margin-right:20px;
}
.next_step{
     width:100%;
     display:flex;
     justify-content:center;
     align-items:center;
     margin-top:80px;
     margin-bottom:30px;
     .next_step_btn{
          width:300px;
     }
}
</style>